import "./chat.css";

import React, { useRef } from "react";

import { Link, useMatch } from "react-router-dom";

import Loader from "../assets/Rolling-1s-200px.gif";

import moment from "moment";
import welcome from "../assets/welcome.svg";

import { useMemo, useCallback, useEffect, useContext, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";

import InfoIcon from "@mui/icons-material/Info";

import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

import CryptoJS from "crypto-js";

import { AES, enc } from "crypto-js";

import Popup from "reactjs-popup";

import "reactjs-popup/dist/index.css";

import {
  MainContainer,
  Sidebar,
  ConversationList,
  Conversation,
  Avatar,
  Search,
  ChatContainer,
  ConversationHeader,
  MessageGroup,
  Message,
  MessageSeparator,
  MessageList,
  MessageInput,
  TypingIndicator,
  InfoButton,
  VideoCallButton,
  VoiceCallButton,
  StarButton,
} from "@chatscope/chat-ui-kit-react";

import HashImage from "../assets/hashtag.png";

import {
  useChat,
  ChatMessage,
  MessageContentType,
  MessageDirection,
  MessageStatus,
} from "@chatscope/use-chat";

import { MessageContent, TextContent, User } from "@chatscope/use-chat";

import { Button, Navbar } from "react-bootstrap";

import NavbarChat from "./Navbar";

import SwipeableTemporaryDrawer from "./buttonSlider/SliderBtn";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { useNavigate, useLocation } from "react-router-dom";

import ModalImage from "react-modal-image";

import { GlobalContext } from "../Context/context";

import { message } from "antd";

import { Console, log } from "console";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const Chat = ({ user }: { user: User }) => {
  let previousDate: any = null;

  const navigate = useNavigate();

  // const msgListRef = useRef();
  const msgListRef = useRef<HTMLDivElement>(null);

  const [isScrollbarActive, setIsScrollbarActive] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [filteredChannels, setFilteredChannels] = useState([]);

  const [notificationState, setNotificationState] = useState(() => {
    const storedState = localStorage.getItem("notificationState");
    return storedState ? JSON.parse(storedState) : {};
  });

  const [notificationResponse, setNotificationResponse] = useState({});

  const [notification, setNotification] = useState("enable");

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [user_Token, setUserToken] = useState(localStorage.getItem("token"));

  const [userId, setUserId] = useState(localStorage.getItem("user_ID"));

  const [sidebarStyle, setSidebarStyle] = useState({});

  let [userChannel, setUserChannel] = useState<any>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [chatContainerStyle, setChatContainerStyle] = useState({});

  const [messages, setMessages] = useState<any>([]);

  const [name, setName] = useState(null);

  const [activeName, setActiveName] = useState("");

  const [activeAvatar, setActiveAvatar] = useState("");

  const [notificationChannelId, setNotificationChannelId] = useState("");

  const [loading, setLoading] = useState(false);

  const [conversationContentStyle, setConversationContentStyle] = useState({});

  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});

  const [activeChannel, setActiveChannel] = useState(null);

  const [activeConvo, setActiveConvo] = useState(null);

  const handleBackClick = () => {
    setSidebarVisible(!sidebarVisible);
    setActiveConvo(null);
  };

  const [selectedChats, setSelectedChats] = useState(false);

  let { state, dispatch } = useContext<any>(GlobalContext);

  const [selectedImage, setSelectedImage] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeNot, setActiveNot] = useState("");

  const [viewerVisible, setViewerVisible] = useState(false);

  const [viewerIndex, setViewerIndex] = useState(0);

  const [viewerImages, setViewerImages] = useState<string[]>([]);

  const handleImageClick = (imageSrc: string) => {
    setViewerVisible(true);
    setViewerIndex(0);
    setViewerImages([imageSrc]);
  };

  const handleClick = (link: any) => {
    console.log("hello ", link);
  };

  const encryptId = (id: any) => {
    const encrypted = AES.encrypt(
      id.toString(),
      "secret passphrase"
    ).toString();

    return enc.Hex.stringify(enc.Utf8.parse(encrypted));
  };

  const headers = {
    Authorization: `Bearer ${user_Token}`,
  };
  const headers1 = {
    headers: {
      Authorization: `Bearer ${user_Token}`,
    },
  };

  const handleConversationClick = useCallback(
    async (channelId) => {
      setActiveConvo(channelId);
      setIsScrollbarActive(true);

      setSelectedChats(true);
      setLoading(true);
      setNotificationChannelId(channelId);
      setActiveNot("");

      try {
        if (sidebarVisible) {
          setSidebarVisible(false);
        }

        const response = await axios.get(
          `${state.baseUrl}api/channel/message/${channelId}`,

          {
            headers: headers,
          }
        );
        setMessages(response.data.data);
        // console.log(response.data.channel_details.name);
        setActiveName(response.data.channel_details.name);
        setActiveAvatar(response.data.channel_details.icon);

        setActiveNot(response.data.notification);

        setTimeout(() => {
          var divList = document.getElementsByClassName("theMEssage");
          var lastDiv = divList[divList.length - 1];
          lastDiv.scrollIntoView();
        }, 2000);

        // console.log(msgListRef.current.scrollToBottom);

        // msgListRef.current.scrollToBottom("auto");

        // Process the response as needed
      } catch (error) {
        console.log("Error fetching messages:", error);
      }
      setLoading(false);
    },
    [sidebarVisible, setSidebarVisible]
  );

  const windowhyu = window.addEventListener("message", (event) => {
    const data = event.data;
  });

  const handleLikeClick = async (channelId: any, status: any) => {
    const updatedState = { ...notificationState };
    const enable = !updatedState[channelId]; // Toggle the notification setting

    // Send the notification setting to the server
    await sendNotification(channelId, status);

    // Update the local notification state based on the response
    updatedState[channelId] = enable;
    setNotificationState(updatedState);
    localStorage.setItem("notificationState", JSON.stringify(updatedState));
  };

  const sendNotification = async (channelId: any, status: any) => {
    try {
      const response = await axios.put(
        `${state.baseUrl}api/notification/setting`,
        {
          channel_id: channelId,
          setting: status,
        },
        {
          headers: {
            Authorization: `Bearer ${user_Token}`,
          },
        }
      );
      console.log(status);
      console.log(response.data.data);
      setActiveNot(response.data.data);
      // Handle the response as needed
    } catch (error) {
      console.log("Error sending notification:", error);
    }
  };

  const getChannels = async (itv: any) => {
    // console.log('Muazan')
    // console.log(itv)
    if (itv) setLoading(true);

    try {
      const response = await axios.get(
        `${state.baseUrl}api/channel-user`,
        headers1
      );

      const channels = response?.data?.data;

      setUserChannel(channels);

      // Check notification state for each channel and update local state
      const updatedState = { ...notificationState };

      channels.forEach((channel: any) => {
        const channelId = channel.channel_id;
        const isNotificationEnabled = channel.notification === "enable";
        updatedState[channelId] = isNotificationEnabled;
      });

      setNotificationState(updatedState);

      localStorage.setItem("notificationState", JSON.stringify(updatedState));
    } catch (error) {
      console.log("axios error: ", error);
    }

    setLoading(false);
  };

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Access individual query parameters
    const parameterValue = queryParams.get("open_channel");

    if (parameterValue) handleConversationClick(parameterValue);
  }, [location]);

  useEffect(() => {
    let isNewMessage = false; // Flag to track new messages

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${state.baseUrl}api/channel/message/${activeConvo}`,
          { headers }
        );

        if (response.data.data.length > messages.length) {
          // New message(s) received
          isNewMessage = true;
          setMessages(response.data.data);
        }
      } catch (error) {
        console.log("Error fetching messages:", error);
      }
    };

    const interval = setInterval(() => {
      if (activeConvo) {
        fetchData();
      }
    }, 5000);

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [activeConvo, messages.length, state.baseUrl, headers]);

  useEffect(() => {}, [userChannel, messages]);

  const MessageTime = ({ time }: any) => {
    return <span className="message-time">{time}</span>;
  };
  useEffect(() => {
    getChannels(true);

    if (sidebarVisible) {
      setSidebarStyle({
        display: "flex",
        flexBasis: "auto",
        width: "320px",
        maxWidth: "100%",
      });
      setConversationContentStyle({
        display: "flex",
      });
      setConversationAvatarStyle({
        marginRight: "1em",
      });
      setChatContainerStyle({
        display: "none",
      });
    } else {
      setSidebarStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({});
      setChatContainerStyle({});
    }
  }, [
    sidebarVisible,
    setSidebarVisible,
    setConversationContentStyle,
    setConversationAvatarStyle,
    setSidebarStyle,
    setChatContainerStyle,
  ]);

  // update channels
  useEffect(() => {
    // Fetch data initially
    getChannels(false);

    // Set interval to fetch data every 10 seconds
    const intervalId = setInterval(getChannels, 5000);

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Get all chat related values and methods from useChat hook
  const {
    currentMessages,
    conversations,
    activeConversation,
    setActiveConversation,
    sendMessage,
    getUser,
    currentMessage,
    setCurrentMessage,
    sendTyping,
    setCurrentUser,
  } = useChat();

  useEffect(() => {
    setCurrentUser(user);
  }, [user, setCurrentUser]);

  // Get current user data
  const [currentUserAvatar, currentUserName] = useMemo(() => {
    if (activeConversation) {
      const participant =
        activeConversation.participants.length > 0
          ? activeConversation.participants[0]
          : undefined;

      if (participant) {
        const user = getUser(participant.id);

        if (user) {
          // console.log("user", user);
          return [<Avatar src={user.avatar} />, user.username];
        }
      }
    }

    return [undefined, undefined];
  }, [activeConversation, getUser]);

  const handleChange = (value: string) => {
    setCurrentMessage(value);
    if (activeConversation) {
      sendTyping({
        conversationId: activeConversation?.id,
        isTyping: true,
        userId: user.id,
        content: value,
        throttle: true,
      });
    }
  };

  const handleSend = (text: string) => {
    const message = new ChatMessage({
      id: "", // Id will be generated by storage generator, so here you can pass an empty string
      content: text as unknown as MessageContent<TextContent>,
      contentType: MessageContentType.TextHtml,
      senderId: user.id,
      direction: MessageDirection.Outgoing,
      status: MessageStatus.Sent,
    });

    if (activeConversation) {
      sendMessage({
        message,
        conversationId: activeConversation.id,
        senderId: user.id,
      });
    }
  };

  const getTypingIndicator = useCallback(() => {
    if (activeConversation) {
      const typingUsers = activeConversation.typingUsers;

      if (typingUsers.length > 0) {
        const typingUserId = typingUsers.items[0].userId;

        // Check if typing user participates in the conversation
        if (activeConversation.participantExists(typingUserId)) {
          const typingUser = getUser(typingUserId);

          if (typingUser) {
            return (
              <TypingIndicator content={`${typingUser.username} is typing`} />
            );
          }
        }
      }
    }

    return undefined;
  }, [activeConversation, getUser]);
  interface WindowWithWebView extends Window {
    ReactNativeWebView?: {
      postMessage(message: string): void;
    };
  }

  const logout = () => {
    dispatch({
      type: "USER_LOGOUT",
    });
    localStorage.clear();
    //  if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView.postMessage("logout");
    // }
    const windowWithWebView = window as WindowWithWebView;
    if (windowWithWebView.ReactNativeWebView) {
      windowWithWebView.ReactNativeWebView.postMessage("logout");
    }
    navigate("/login");
  };

  const AccountSetting = () => {
    navigate("/account-setting");
  };

  const helloworld = () => {
    // console.log("abc");
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchAppChannels = async () => {
    try {
      const response = await axios.get(`${state.baseUrl}api/channel/message/`, {
        headers,
      });

      // New message(s) received
    } catch (error) {
      console.log("Error fetching messages:", error);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        position: "relative",
      }}
    >
      <MainContainer responsive className="border-0  main-container">
        <Sidebar
          position="left"
          scrollable={true}
          style={sidebarStyle}
          id="side-bar"
          className={`scrollbar-container cs-sidebar cs-sidebar--left sidebar ps ${
            isScrollbarActive ? "ps--active-x" : "sidebar"
          }`}
        >
          {/* <NavbarChat /> */}
          <div
            style={{
              borderBottom: "1px solid #cedbe3",

              paddingLeft: 15,
              paddingRight: 10,
              height: 74,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: 25,
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                Channels
              </div>
            </div>
            <div className="dropdown">
              <MoreHorizIcon
                className="dropdown-toggle h-dropdown"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                color="primary"
              />

              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li className="dropdown-item" onClick={AccountSetting}>
                  <Link to={`/account-setting`} className="account-setting">
                    Account Setting
                  </Link>
                </li>
                <li
                  className="dropdown-item"
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                  onClick={logout}
                >
                  Logout
                  {/* <LogoutIcon /> */}
                </li>
              </ul>
            </div>
          </div>

          {/* side bar */}
          {isLoading && <div>Loading...</div>}
          <ConversationList id="muazan" className="conversationList">
            {/* Search Input */}
            <div className="searchContainer">
              <Search
                className="searchInput"
                placeholder="Search Channels..."
                value={searchQuery}
                onChange={(value: any) => setSearchQuery(value)}
                onClearClick={() => setSearchQuery("")}
              />
            </div>

            {userChannel
              .filter((c: any) =>
                c.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((c: any) => {
                return (
                  <Conversation
                    className={
                      activeConvo === c.id ? "active" : "conversations"
                    }
                    key={c.id}
                    unreadCnt={c.unread_messages}
                    onClick={() => {
                      handleConversationClick(c.id);
                      setActiveName(c.name);
                    }}
                  >
                    <Conversation.Content
                      className="conversationsContent"
                      name={c.name}
                      //  name={'name'}
                      style={conversationContentStyle}
                      onClick={() => {
                        setActiveConversation(c.id);
                        setActiveName(c.name);
                      }}
                    />
                    <Avatar
                      src={c.channel_icon ?? HashImage}
                      onClick={() => {
                        setActiveConversation(c.id);
                        setActiveName(c.name);
                      }}
                    />
                  </Conversation>
                );
              })}
          </ConversationList>
        </Sidebar>

        {/* messages List */}

        {loading && (
          <div className="loader">
            <img height={50} src={Loader} alt="loading" />
          </div>
        )}

        {!loading && (
          <ChatContainer style={chatContainerStyle} className="chat-container">
            <ConversationHeader>
              <ConversationHeader.Back onClick={handleBackClick} />
              <Avatar src={activeAvatar ? activeAvatar : HashImage} />
              <ConversationHeader.Content
                className="activeName"
                userName={activeName}
              />

              <ConversationHeader.Actions>
                {/* {activeNot} */}
                <div
                  onClick={() =>
                    handleLikeClick(
                      notificationChannelId,
                      activeNot == "enable" ? "disable" : "enable"
                    )
                  }
                >
                  {activeNot == "enable" ? (
                    <NotificationsActiveOutlinedIcon color="primary" />
                  ) : (
                    <NotificationsOffIcon color="primary" />
                  )}
                </div>
              </ConversationHeader.Actions>
            </ConversationHeader>

            <MessageList ref={msgListRef} className="messagesList">
              {messages.map((g: any, index: number) => {
                const currentDate = moment(g.created_at).format("D/M/YY");
                const previousDate =
                  index > 0
                    ? moment(messages[index - 1].created_at).format("D/M/YY")
                    : null;
                const showDateSeparator = currentDate !== previousDate;

                return (
                  <React.Fragment key={g.id}>
                    {showDateSeparator && (
                      <MessageSeparator className="date-separator">
                        {currentDate}
                      </MessageSeparator>
                    )}

                    <Message
                      className="messages"
                      model={{
                        direction: "incoming",
                        position: "single",
                      }}
                    >
                      {g.content_type === "html" ? (
                        <Message.HtmlContent
                          html={`<a href="/m/${encryptId(g.id)}">${
                            state.localURI
                          }/m/${encryptId(g.id)}</a>
                          <span class="message-time">${moment(
                            g.created_at
                          ).format("hh:mm A")}</span>`}
                        ></Message.HtmlContent>
                      ) : g.content_type === "image" ? (
                        <Message.CustomContent className="theMEssage">
                          {/* <ModalImage
                            small={`${state.baseUrl}/AppLaravel/public/uploads/${g.image}`}
                            large={`${state.baseUrl}/AppLaravel/public/uploads/${g.image}`}
                            hideDownload={true}
                            hideZoom={false}
                            style={{
                              background: "transparent",
                              maxWidth: "500px",
                            }}
                            className="channelImage"
                            onClick={() =>
                              handleClick(
                                `${state.baseUrl}/AppLaravel/public/uploads/${g.image}`
                              )
                            }
                          /> */}
                          {/* <img
                            src={`${state.baseUrl}/AppLaravel/public/uploads/${g.image}`}
                          /> */}

                          <Popup
                            className="channelImage"
                            trigger={
                              <img
                                src={`${state.baseUrl}storage/uploads/${g.image}`}
                              />
                            }
                            position="center center"
                            modal
                          >
                            {(close: any) => (
                              <div>
                                <button
                                  className="button lp-button"
                                  onClick={() => {
                                    console.log("modal closed ");
                                    close();
                                  }}
                                >
                                  <CloseIcon />
                                </button>

                                <TransformWrapper>
                                  <TransformComponent>
                                    <img
                                      src={`${state.baseUrl}storage/uploads/${g.image}`}
                                    />
                                  </TransformComponent>
                                </TransformWrapper>
                              </div>
                            )}
                          </Popup>

                          {/* <Popup
                            className="channelImage"
                            trigger={
                              <img
                                src={`${state.baseUrl}storage/uploads/${g.image}`}
                              />
                            }
                            position="center center"
                            modal
                          >
                            <div>
                              <TransformWrapper>
                                <TransformComponent>
                                  <img
                                    src={`${state.baseUrl}storage/uploads/${g.image}`}
                                  />
                                </TransformComponent>
                              </TransformWrapper>
                            </div>
                          </Popup> */}
                          <span className="message-time">
                            {moment(g.created_at).format("hh:mm A")}
                          </span>
                        </Message.CustomContent>
                      ) : (
                        <Message.HtmlContent
                          className="theMEssage"
                          html={`${
                            g.content
                          } <br/><span class="message-time">${moment(
                            g.created_at
                          ).format("hh:mm A")}</span>`}
                        ></Message.HtmlContent>
                      )}
                    </Message>
                  </React.Fragment>
                );
              })}
            </MessageList>
          </ChatContainer>
        )}
      </MainContainer>
    </div>
  );
};
export default Chat;
